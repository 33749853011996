<div class="layout-topbar">
  <div class="layout-topbar-logo">
    <div class="row">
      <div class="col-2 layout-topbar-logo">
        <img src="../../../assets/images/Logo_KTB_2022.png" />
      </div>
      <div class="col-10">
        <div>
          <header>{{ app_name.en }}</header>
        </div>
        <div>
          <h5>{{ app_name.th }}</h5>
        </div>
      </div>
    </div>
  </div>

  <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
    <i class="pi pi-bars"></i>
  </button>

  <!-- <button
    #topbarmenubutton
    class="p-link layout-topbar-menu-button layout-topbar-button"
    (click)="layoutService.showProfileSidebar()"
  >
    <i class="pi pi-ellipsis-v"></i>
  </button> -->

  <div #topbarmenu class="layout-topbar-menu" [ngClass]="{
      'layout-topbar-menu-mobile-active':
        layoutService.state.profileSidebarVisible
    }">
    <div class="layout-topbar-logo">
      <div class="row">
        <div class="avatar-layout layout-topbar-logo">
          <img class="avatar" [src]="userImage" />
        </div>
        <div class="mr-4">
          <div>
            <header>{{userEmail}}</header>
          </div>
          <div>
            <h5>{{userRole}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-progress-spinner *ngIf="spinnerService.isLoading | async"></app-progress-spinner>