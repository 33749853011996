import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from "primeng/breadcrumb";
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from "primeng/button";
import { TableModule } from "primeng/table";
import { DropdownModule } from "primeng/dropdown";
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { TabComponent } from './tab/tab.component'
import { ButtonComponent } from './button/button.component'
import { InfoBarComponent } from './info-bar/info-bar.component'

const modules = [
  CommonModule,
  BreadcrumbModule,
  TabViewModule,
  ButtonModule,
  TableModule,
  DropdownModule,
  MessagesModule,
  MessageModule
];

const components = [
  BreadcrumbComponent,
  TabComponent,
  ButtonComponent,
  InfoBarComponent
];

@NgModule({
  declarations: components,
  imports: modules,
  exports: [...components, ...modules],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiModule { }
