<div class="dropdown-container">
  <div class="styled-dropdown-label">
    {{ label }}<span *ngIf="isRequired">*</span>
  </div>
  <p-dropdown
    [options]="options"
    [(ngModel)]="selectedOptions"
    (ngModelChange)="selectedOptionsChange.emit(selectedOptions)"
    (completeMethod)="filterOption($event)"
    [placeholder]="placeholder"
    [disabled]="disabled"
    (onSelect)="onSelect()"
    [formControl]="$any(formGroup.controls[controlName])"
    (onShow)="onPanelShow()"
    (onHide)="onPanelHide()"
    [ngClass]="invalid && warningText && 'error'"
  ></p-dropdown>
  <h5 *ngIf="invalid && warningText">{{ warningText }}</h5>
</div>
