<div class="input-container" [style.width]="width">
  <div class="styled-auto-complete-label">
    {{ label }}<span *ngIf="isRequired">*</span>
  </div>
  <input
    class="styled-input"
    type="text"
    pInputText
    autocomplete="off"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [attr.disabled]="disabled ? '' : null"
    [attr.maxlength]="maxLength"
    [formControl]="$any(formGroup.controls[controlName])"
    (ngModelChange)="valueChange.emit(value)"
    (change)="onChangeValue($event)"
  />
</div>
<h5 *ngIf="Invalid">{{ warningText }}</h5>
