import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
// import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() menuItems: MenuItem[] = [];

  // constructor(public breadcrumbService: BreadcrumbService) {
  //   this.breadcrumbService.menuItems$.subscribe(
  //     (val) => (this.menuItems = val)
  //   );
  // }

  ngOnInit() {}
}
