<p-multiSelect
  [showHeader]="false"
  [filter]="false"
  [options]="data"
  [(ngModel)]="selectedData"
  [defaultLabel]="defaultLabel"
  [optionLabel]="optionLabel"
  [formControl]="$any(formGroup.controls[controlName])"
  display="chip"
  (onPanelShow)="onPanelShow()"
  (onPanelHide)="onPanelHide()"
  (onChange)="onChange()"
  [ngClass]="adjustHeight() ? '': 'p-multiselect-override' "
  [disabled]="disabled"
>
</p-multiSelect>