
  <div class="styled-auto-complete-label">
    {{ label }}<span *ngIf="isRequired">*</span>
  </div>
  <p>{{suggestionText}}</p>
  <div class="input-section">
    <p-inputNumber
      [(ngModel)]="value"
      (ngModelChange)="valueChange.emit(value)"
      mode="decimal"
      [showButtons]="true"
      incrementButtonClass="p-button-rounded p-button-text"
      decrementButtonClass="p-button-rounded p-button-text"
      class="styled-inputnumber"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [formControl]="$any(formGroup.controls[controlName])"
    ></p-inputNumber>
    <div class="suffix"> {{ suffix }} </div>
  </div>
  <h5 *ngIf="invalid">{{ warningText }}</h5>
