import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { SessionQuery } from '../state/session/session.query';
import { AuthService } from 'src/app/auth/auth.service';
import { SessionService } from '../state/session/session.service';
import { Router } from '@angular/router';
import { PopupService } from '../services/popup.service';
import { AppConstants } from '../constants/app.constant';

@Injectable()
export class RequestServiceInterceptor implements HttpInterceptor {
  constants: {
    ACCESS_DENIED_MESSAGE: string;
    ACCESS_DENIED_DESCRIPTION: string;
    DO_NOT_HAVE_PERMISSION_MESSAGE: string;
  };
  token = null;

  constructor(
    private router: Router,
    private sessionQuery: SessionQuery,
    private authService: AuthService,
    private sessionService: SessionService,
    private _ngZone: NgZone,
    private popupService: PopupService
  ) {
    this.constants = {
      ACCESS_DENIED_MESSAGE: AppConstants.MODAL_MESSAGE.ACCESS_DENIED_MESSAGE,
      ACCESS_DENIED_DESCRIPTION:
        AppConstants.MODAL_MESSAGE.ACCESS_DENIED_DESCRIPTION,
      DO_NOT_HAVE_PERMISSION_MESSAGE:
        AppConstants.MODAL_MESSAGE.DO_NOT_HAVE_PERMISSION_MESSAGE,
    };
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.sessionQuery.getAccessToken().subscribe((val) => {
      this.token = val;
    });
    if (this.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          if (error.error.code === 'WF70008') {
            this.popupService.showPopup({
              title: this.constants.DO_NOT_HAVE_PERMISSION_MESSAGE,
              showCancelButton: false,
              confirmButtonText: 'OK',
              icon: 'assets/icons/danger-icon.png',
              description: this.constants.DO_NOT_HAVE_PERMISSION_MESSAGE,
              onConfirm: () => {
                this.authService.logout();
              },
            });
          }
          if (error.error.code === 'WF89401') {
            return this.refreshToken().pipe(
              catchError((refreshError) => {
                this.authService.logout();

                return throwError(refreshError);
              }),
              switchMap((newToken) => {
                request = request.clone({
                  setHeaders: {
                    Authorization: `Bearer ${newToken}`,
                  },
                });
                return next.handle(request);
              })
            );
          }
          if (error.error.code === 'WF70005' && this.token) {
            this.popupService.showPopup({
              title: this.constants.ACCESS_DENIED_MESSAGE,
              showCancelButton: false,
              confirmButtonText: 'OK',
              icon: 'assets/icons/danger-icon.png',
              description: this.constants.ACCESS_DENIED_DESCRIPTION,
              onConfirm: () => {
                this.authService.logout();
              },
            });
          }
        } else {
          return throwError(error);
        }
        return throwError(error);
      })
    );
  }

  refreshToken(): Observable<string> {
    return new Observable((observer) => {
      let newToken: any;
      let refreshToken: any;

      this.sessionQuery.getRefreshToken().subscribe((val) => {
        refreshToken = val;
      });

      this.authService.verifyNewToken(refreshToken).subscribe(
        (res) => {
          newToken = res;
          this.sessionService.setSession(newToken);
          this.sessionService.getRoleId();
          observer.next(newToken.accessToken);
          observer.complete();
        },
        (error: any) => {
          this._ngZone.run(() => {
            this.authService.logout();
            observer.error(error);
          });
        }
      );
    });
  }

  // refreshToken(): Observable<string> {
  //   let newToken: any;
  //   let refreshToken: any;
  //   this.sessionQuery.getRefreshToken().subscribe((val) => {
  //     refreshToken = val;
  //   });
  //   this.authService.verifyNewToken(refreshToken).subscribe(
  //     (res) => {
  //       newToken = res;
  //       this.sessionService.setSession(newToken);
  //       this.sessionService.getRoleId();
  //     },
  //     (error: any) => {
  //       this._ngZone.run(() => {
  //         this.popupService.showPopup({
  //           title: this.constants.DO_NOT_HAVE_PERMISSION_MESSAGE,
  //           showCancelButton: false,
  //           confirmButtonText: 'OK',
  //           icon: 'assets/icons/danger-icon.png',
  //           onConfirm: () => {
  //             this.authService.logout();
  //           },
  //         });
  //       });
  //     }
  //   );
  //   return newToken.accessToken;
  // }
}
