import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { Observable } from 'rxjs';
import { SessionStore, SessionState } from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  constructor(protected override store: SessionStore) {
    super(store);
  }

  getAccessToken(): Observable<any> {
    return this.select((state) => state.accessToken);
  }

  getRefreshToken(): Observable<any> {
    return this.select((state) => state.refreshToken);
  }

  getMenus(): Observable<any> {
    return this.select((state) => state.menuList);
  }
  getUserProfile(): Observable<any> {
    return this.select((state) => state.userProfile);
  }

  getUserCampaign(): Observable<any> {
    return this.select((state) => state.userCampaign)
  }

  getRole(): Observable<any> {
    return this.select((state) => state.ROLE)
  }

  getRoleId(): Observable<any> {
    return this.select((state) => state.userProfile?.roleId)
  }

  getState(): Observable<any> {
    return this.select((state) => state.STATE)
  }

  isLogin(): Observable<any> {
    return this.select((state) => toBoolean(state.accessToken));
  }
}
   