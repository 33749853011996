import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { NotfoundPageComponent } from './features/notfound-page/pages/notfound-page.component';
import { LayoutComponent } from './layout/layout.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./auth/auth.module').then((module) => module.AuthModule),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      {
        path: 'request-summary',
        loadChildren: () =>
          import('./features/request-summary/request-summary.module').then(
            (module) => module.RequestSummaryModule
          ),
        data: {
          breadcrumb: 'Request Summary',
          notfound: true,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'campaign-management',
        loadChildren: () =>
          import(
            './features/campaign-management/campaign-management.module'
          ).then((module) => module.CampaignManagementModule),
        data: {
          breadcrumb: 'Campaign Management',
          notfound: false,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'audit-log',
        loadChildren: () =>
          import('./features/audit-log/audit-log.module').then(
            (module) => module.AuditLogModule
          ),
        data: {
          breadcrumb: 'Audit Log',
          notfound: true,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'transaction-history',
        loadChildren: () =>
          import(
            './features/transaction-history/transaction-history.module'
          ).then((module) => module.TransactionHistoryModule),
        data: {
          breadcrumb: 'Transaction History',
          notfound: false,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'sponsor-management',
        loadChildren: () =>
          import(
            './features/sponsor-management/sponsor-management.module'
          ).then((module) => module.SponsorManagementModule),
        data: {
          breadcrumb: 'Sponsor Management',
          notfound: true,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'customer-management',
        loadChildren: () =>
          import(
            './features/customer-management/customer-management.module'
          ).then((module) => module.CustomerManagementModule),
        data: {
          breadcrumb: 'Customer Management',
          notfound: false,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'company-management',
        loadChildren: () =>
          import(
            './features/company-management/company-management.module'
          ).then((module) => module.CompanyManagementModule),
        data: {
          breadcrumb: 'Company Management',
          notfound: false,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./features/user-management/user-management.module').then(
            (module) => module.UserManagementModule
          ),
        data: {
          breadcrumb: 'User Management',
          notfound: true,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'import',
        loadChildren: () =>
          import('./features/import/import.module').then(
            (module) => module.ImportModule
          ),
        data: {
          breadcrumb: 'Import',
          notfound: true,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'email-management',
        loadChildren: () =>
          import('./features/email-management/email-management.module').then(
            (module) => module.EmailManagementModule
          ),
        data: {
          breadcrumb: 'Email Management',
          notfound: true,
        },
        canActivate: [AuthGuard],
      },
    ],
  },
  { path: 'notfound', component: NotfoundPageComponent },
  // {
  //   path: '**', redirectTo: 'notfound',
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
