import { Observable, of } from 'rxjs';
import { LogEntry } from 'src/app/core/services/log/log.service';

export abstract class LogPublisher {
  location: string = '';
  abstract log(record: LogEntry): Observable<boolean>;
  abstract clear(): Observable<boolean>;
}

export class LogConsole extends LogPublisher {
  log(entry: LogEntry): Observable<boolean> {
    return of(true);
  }

  clear(): Observable<boolean> {
    console.clear();
    return of(true);
  }
}
