export const APP_NAME_EN: string = 'Privilege System';
export const APP_NAME_TH: string = 'ระบบสิทธิกลาง';
export const APP_VERSION: string = '0.1';
export const LOADING: string = 'loading';

export const APP_CONST = {
  DEBOUNCE: {
    DEFAULT_KEYPRESS_DEBOUNCETIME: 500,
    DEFAULT_BUTTON_CLICK_DEBOUNCETIME: 800,
    DEFAULT_API_CALLED_DEBOUNCETIME: 300,
  },
  PAGINATION: {
    DEFAULT_PAGE_SIZE: 25,
  },
  IS_LOADING_TOKEN: {
    GETTING_USER: 'GETTING_USER',
    UPDATING_USER: 'UPDATING_USER',
  },
};

export const StorageConstant = {
  //: any
  AD_TOKEN: 'AdToken',
  ACCOUNT: 'Account',
  MENU: 'Menu',
  APP_TOKEN: 'AppToken',
  API_VERSION: 'ApiVersion',
  API_ENVIRONMENT: 'ApiEnvironment',
  APP_EXPIRE: 'AppExp',
};

export const SORT_DIRECTION = {
  ASCENDING: 'A',
  DESCENDING: 'D',
};

export const AppConstants = {
  FOOTER_MESSAGE:
    'เว็บไซต์นี้แสดงผลได้ดีบนเบราว์เซอร์ Chrome Firefox และ Safari สงวนสิทธิ์ พ.ศ. 2563 บมจ. ธนาคารกรุงไทย | SWIFT CODE : krththbk',

  MODAL_MESSAGE: {
    LOGOUT_MESSAGE: 'Are you sure you want to log out?',
    AUTO_LOGOUT_MESSAGE: 'Automatic Log out due to Inactivity',
    PLEASE_PROVIDE_INFO_MESSAGE: 'Please Provide Any Information',
    DOWNLOAD_COMPLETE_MESSAGE: 'Download Completed',
    UNABLE_LOAD_DATA_MESSAGE: 'Unable to load data. Please try again',
    DO_NOT_HAVE_PERMISSION_MESSAGE: "Don't have permission",
    EXEEDS_DOWNLOAD_LIMIT_MESSAGE:
      'Exceeds download limits.The maximum number of records to .CSV file is 10,000 records',
    FILE_EXEEDS_MAXIMUM_50MB_MESSAGE:
      'The file exceeds maximum import file size: <b>50MB</b>',
    FILE_EXEEDS_MAXIMUM_USER_MESSAGE:
      'The file exceeds maximum import file size: 100 User',
    ACCESS_DENIED_MESSAGE: 'Access denied',
    ACCESS_DENIED_DESCRIPTION: 'Please re-login or contact administrator.',
    CID_NOT_EXISTS: 'Citizen ID does not exist in the system.',
    DATA_NOT_EXISTS: 'Search data does not exist in the system',
    INTERNAL_SERVER_ERROR: 'Internal Server Error, Please contact ITPM',
    COMPANY_INFO_NOT_EXISTS: 'Company Information does not exist',
  },
  TOASTR: {
    UPDATED_SUCCESSFULLY_MESSAGE: 'Updated successfully.',
    USER_ADDED_SUCCESSFULLY_MESSAGE: 'User added successfully.',
    FILE_IMPOERTED_MESSAGE:
      'Your file has been imported. Please check your email for the result.',
    CREATED_SUCCESSFULLY: 'Created successfully.',
    REQUEST_SUCCESSFULLY: 'The request has been submitted successfully.',
    YOUR_CHANGE_SAVED_MESSAGE: 'Your change has been saved.',
  },

  LOGIN_PAGE: {
    APP_NAME_EN: 'Privilege System',
    APP_NAME_TH: 'ระบบสิทธิกลาง',
    LOGIN_WITH: 'Login with',
  },
  UNAUTHORIZED_PAGE: {
    MAIN_MESSAGE:
      'Unable to access the Privilege System Web Portal due to unauthorized email address. Please use the authorized email address',
    LINK_MESSAGE: 'Sign in with a different account',
    LOGOUT_WITHOUT_VPN_MESSAGE:
      'Unable to access the Privilege System Web Portal due to network error. Please check your network setting or try again later.',
    LOGOUT_WITHOUT_VPN_LINK_MESSAGE: 'Log in to privilege system',
  },
  NOTFOUND_PAGE: {
    MAIN_MESSAGE: '404 Page not found',
    LINK_MESSAGE: 'Back to page',
  },
  MENU_LIST: [
    {
      menuId: 1,
      menuName: 'Request Summary',
      menuLevel: 0,
      menuParentId: 0,
    },
    {
      menuId: 2,
      menuName: 'Sponsor Management',
      menuLevel: 0,
      menuParentId: 0,
    },
    {
      menuId: 3,
      menuName: 'Campaign Management',
      menuLevel: 0,
      menuParentId: 0,
    },
    {
      menuId: 4,
      menuName: 'Customer Management',
      menuLevel: 0,
      menuParentId: 0,
    },
    {
      menuId: 5,
      menuName: 'Company Management',
      menuLevel: 0,
      menuParentId: 0,
    },
    {
      menuId: 6,
      menuName: 'Email Management',
      menuLevel: 0,
      menuParentId: 0,
    },
    {
      menuId: 7,
      menuName: 'Transaction History',
      menuLevel: 0,
      menuParentId: 0,
    },
    {
      menuId: 8,
      menuName: 'User Management',
      menuLevel: 0,
      menuParentId: 0,
    },
    {
      menuId: 9,
      menuName: 'Import',
      menuLevel: 0,
      menuParentId: 0,
    },
    {
      menuId: 10,
      menuName: 'Audit Log',
      menuLevel: 0,
      menuParentId: 0,
    },
    {
      menuId: 11,
      menuName: 'Log Out',
      menuLevel: 0,
      menuParentId: 0,
    },
  ],
};
