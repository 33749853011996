import { Pipe, PipeTransform } from '@angular/core';
import { DatatableComponent } from './datatable.component';

@Pipe({
  name: 'stringToDateCreatedDateTime',
})
export class StringToDateCreatedDateTime implements PipeTransform {
  constructor(readonly datatable: DatatableComponent) {}
  transform(value: string | null | undefined): string {
    if (!value) {
      return '';
    }

    let year = value!.slice(6, 10);
    if (value!.slice(4, 8) === '0000') {
      return year;
    }

    let month = value!.slice(3, 5);
    let day = value!.slice(0, 2);

    const monthNames = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    if (monthNames[parseInt(month) - 1] === undefined) {
      month = value!.slice(3, 5);
      day = value!.slice(0, 2);
    }
    let time = value!.substring(10, 19);

    const formattedDate = `${day} ${monthNames[parseInt(month) - 1]} ${parseInt(
      year
    )} ${time}`;
    return formattedDate;
  }
}
