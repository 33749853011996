import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/core/constants/app.constant';

@Component({
  selector: 'app-notfound-page',
  templateUrl: './notfound-page.component.html',
  styleUrls: ['./notfound-page.component.scss']
})
export class NotfoundPageComponent implements OnInit {
  constants: {
    mainMessage: string;
    linkMessage: string;
    footerMessage: string;
  }
  constructor() {
    this.constants = {
      mainMessage: AppConstants.NOTFOUND_PAGE.MAIN_MESSAGE,
      linkMessage: AppConstants.NOTFOUND_PAGE.LINK_MESSAGE,
      footerMessage: AppConstants.FOOTER_MESSAGE
    }
  }

  ngOnInit(): void {
  }

}
