import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private config = new Subject();
  configObservable = this.config.asObservable();

  showPopup(config: any) {
    this.config.next({ display: true, ...config });
  }

  hidePopup(config?: any) {
    this.config.next({
      ...config,
      display: false,
    });
  }

  isShow() {
    return this.configObservable
  }
}
