import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() type = 'button';
  @Input() label = 'Search';
  @Input() width = '100%';
  @Input() height = '48px';
  @Input() classBtn = 'primary';
  @Input() iconName = ''
  @Input() disabled = false;
  @Output() clickEvt = new EventEmitter();

  constructor() { }

  onClick() {
    if (!this.disabled) {
      this.clickEvt.emit();
    }
  }

}
