<p-dialog [(visible)]="display" [modal]="true" [style]="{width: width}" [draggable]="false" [closable]="false">
    <header>
        <body class="center">{{title}}</body>
    </header>
    <br>
    <content>
        <p class="center">
            {{message}}
        </p>
    </content>
    <ng-template pTemplate="footer" *ngIf="cancelButton || confirmButton">
        <div class="row">
            <div class="col" *ngIf="cancelButton">
                <button pButton pRipple type="button" [label]="cancelButtonLabel" styleClass="p-button-text"
                    class="center cancel" [style]="{width: cancelButtonWidth}" (click)="onCancelClick()"></button>
            </div>
            <div class="col" *ngIf="confirmButton">
                <button pButton pRipple type="button" [label]="confirmButtonLabel" styleClass="p-button-text"
                    class="center confirm" [style]="{width: confirmButtonWidth}" (click)="onConfirmClick()"></button>
            </div>
        </div>
        <br>
    </ng-template>
</p-dialog>
