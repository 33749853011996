import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { EncrDecrService } from './encr-decr.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  protected secretKey: string = '';
  
  constructor(private encrDecr: EncrDecrService,
    private router: Router,
    private decimalPipe: DecimalPipe) { }

    findPathDataFormatted(obj: any, item: any, objName: string) {
      var path = this.findPathData(obj, item); // first let's get path array to item (if it exsits)
      if (path === null) {
        // if it does't exits
        return ''; // return something to signal its inexistance
      }
      return objName + ['["'] + path.join('"]["') + '"]'; // otherwise format the path array into a string and return it
    }

    findPathData(obj: any, item: any): any {
      // Object.keys(obj).forEach((key) => {
      for (var key in obj) {
        // for each key in obj is either an object or an array
        if (obj[key] && typeof obj[key] === 'object') {
          // if the current property (value of obj[key]) is also an object/array
          var result = this.findPathData(obj[key], item); // try finding item in that object
          if (result) {
            // if we find it
            result.unshift(key); // we shift the current key to the path array (result will be an array of keys)
            return result; // and we return it to our caller
          }
        } else if (obj[key] === item) {
          // otherwise (if obj[key] is not an object or array) we check if it is the item we're looking for
          return [key]; // if it is then we return the path array (this is where the path array constructed)
        }
      }
    }

    setLocalStorage(name: string, data: any): void {
      let dataEncr = this.encrDecr.set(this.secretKey, JSON.stringify(data));
      localStorage.setItem(
        name,
        environment.production ? dataEncr : JSON.stringify(data)
      );
    }

    getLocalStorage(name: string): any {
      let dataFromLS = localStorage.getItem(name);
      return dataFromLS === null
        ? null
        : JSON.parse(
            environment.production
              ? this.encrDecr.get(this.secretKey, localStorage.getItem(name))
              : localStorage.getItem(name) || '{}'
          );
    }

    toStringDecimal(input: string | number, decimals: Number = 0): string {
      const currencyChars = new RegExp('[.,]', 'g');
      if (typeof input !== 'string') {
        input = input + '';
      }
      let arrNum = input.split('.');
      const numberFormat = parseFloat(
        String(arrNum[0]).replace(currencyChars, '') +
          '.' +
          String(arrNum[1]).replace(currencyChars, '')
      );
  
      // 2. format the number (add commas)
      return (
        this.decimalPipe.transform(numberFormat, '1.' + decimals, 'en-US') || ''
      );
    }

    addDays(date: Date, days: number) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    parseNumber(
      input: number | string | null | undefined,
      output_type: 'int' | 'float'
    ) {
      if (input === null || typeof input === undefined || input === '') {
        return null;
      } else {
        switch (output_type) {
          case 'int':
            return parseInt((input + '').replace(',', ''));
          case 'float':
            return parseFloat((input + '').replace(',', ''));
        }
      }
  }

    encryptionQueryParams(obj: any): string {
      let json: string = JSON.stringify(obj);
      let encode: string = btoa(json);
      let encryption: string = this.encrDecr.set('', encode);
      let encodeURI: string = encodeURIComponent(encryption);
      let str = encodeURI;
      return str;
    }

    decryptionQueryParams(str: string): any {
      let decodeURI: string = decodeURIComponent(str);
      let decryption: string = this.encrDecr.get('', decodeURI);
      let decode: string = atob(decryption);
      let obj: any = JSON.parse(decode);
      return obj;
    }

    canAccessMenu(menu_code: string) {
  
      return true;
    }

    logOut() {
      //this.authorizationService.logout();
      // this.router.navigateByUrl("/login");
    }
}


