import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent implements OnInit {

  @Input() confirmButton: boolean = false;
  @Input() cancelButton: boolean = false;
  @Input() display: boolean = false;
  @Input() confirmButtonLabel: string = "Confirm";
  @Input() cancelButtonLabel: string = "Cancel";
  @Input() title: string = "Title";
  @Input() message: string = "Message";
  @Input() width: string = "auto";
  @Input() confirmButtonWidth: string = "150px";
  @Input() cancelButtonWidth: string = "150px";
  @Output() displayChange = new EventEmitter();
  @Output() buttonSelected = new EventEmitter();
  @Output() cancelClick = new EventEmitter();
  @Output() comfirmClick = new EventEmitter();

  @HostBinding("style.--cancelButtonColor") cancelButtonColor: string = '#000D36';

  constructor() { }

  ngOnInit(): void {
  }

  showDialog() {
    this.display = true;
  }
  onCancelClick() {
    if (this.cancelButton) {
      this.cancelClick.emit();
      this.display = false;
    }
  }
  onConfirmClick() {
    if (this.confirmButton) {
      this.comfirmClick.emit();
      this.display = false;
    }
  }
}

