<div class="layout-wrapper" [ngClass]="containerClass">
  <app-header></app-header>
  <div class="layout-main-container">
    <div class="layout-sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
  <div class="layout-mask"></div>
</div>
