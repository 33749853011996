import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { persistState, akitaConfig } from '@datorama/akita';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { debounceTime } from 'rxjs';

akitaConfig({
  resettable: true
});

export const storage = persistState({
  key: 'SESSION',
  storage: sessionStorage,
  enableInNonBrowser: false,
  include:['session'],
  preStorageUpdateOperator: () => debounceTime(400),
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
