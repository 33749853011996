import { Component, OnInit, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-dropdown',
  templateUrl: './form-dropdown.component.html',
  styleUrls: ['./form-dropdown.component.scss'],
})
export class FormDropdownComponent implements OnInit {
  value : any
  @Input() isRequired : boolean = false;
  @Input() label: string = 'test';
  @Input() multiple: boolean = false
  @Input() options: any[] = [];
  @Input() placeholder: string = 'Please select'
  @Input() disabled: boolean = false

  @Input() warningText: any = '';
  @Input() invalid: boolean = false;
  @HostBinding()("style.--maxWidth")maxWidth : string = '450px'
  @HostBinding()("style.--minWidth")minWidth : string = '300px'
  @HostBinding()("style.--width")width : string = '24vw'

  @Input() selectedOptions: any
  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() controlName: string = '';
  @Output() selectedOptionsChange = new EventEmitter<any>()

  @HostBinding('style.--matrix') matrix: string = 'matrix(1, 0, 0, 1, 0, 0)';
  
  filteredOptions: any[] = [];
  constructor() {}

  ngOnInit(): void {
    let control: FormControl = new FormControl('');
    this.formGroup.addControl(this.controlName, control);
  }

  filterOption(event: any) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.options.length; i++) {
      let option = this.options[i];
      if (option.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(option);
      }
    }

    this.filteredOptions = filtered;
  }

  onSelect(){
    this.selectedOptionsChange.emit(this.selectedOptions)
  }

  onPanelShow() {
    this.matrix = 'matrix(1, 0, 0, -1, 0, 0)';
  }

  onPanelHide() {
    this.matrix = 'matrix(1, 0, 0, 1, 0, 0)';
  }
}
