<p-dialog
  [(visible)]="config.display"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  [modal]="true"
  [showHeader]="false"
  class="timeout-modal"
>
  <img
    *ngIf="config.icon"
    [src]="config.icon"
    style="width: 64px"
    class="row-1 align-self-center"
  />
  <div>
    <p *ngIf="!isHTML(config.title) && config.title">
      <b>{{ config.title }}</b>
    </p>
    <p *ngIf="isHTML(config.title)" [innerHTML]="config.title"></p>
    <p *ngIf="!isHTML(config.description) && config.description">
      {{ config.description }}
    </p>
    <p *ngIf="isHTML(config.description)" [innerHTML]="config.description"></p>
  </div>
  <div
    [style]="{ width: config.buttonWidth }"
    class="d-flex justify-content-around w-full"
  >
    <button
      *ngIf="config.showCancelButton"
      pButton
      pRipple
      type="button"
      [label]="config.cancelButtonText"
      (click)="onCancel()"
      class="p-button-rounded p-button-danger col-5 p-button-outlined mr-2 ml-2"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      [label]="config.confirmButtonText"
      (click)="onConfirm()"
      class="p-button-rounded mr-2 ml-2"
      style="background-color: #000d36"
      [ngClass]="config.showCancelButton ? 'col-5' : 'col-6'"
    ></button>
  </div>
</p-dialog>
