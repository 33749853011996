import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-topright-popup',
  templateUrl: './topright-popup.component.html',
  styleUrls: ['./topright-popup.component.scss']
})
export class ToprightPopupComponent implements OnInit {
  @Input() confirmButton: boolean = false;
  @Input() cancelButton: boolean = false;
  @Input() display: boolean = false;
  @Input() title: string = "Title";
  @Input() width: string = "25";
  widthVW: string = this.width+"vw"
  // @Input() message: string = "Message";
  // @Input() showMessage: boolean = false;
  @Output() displayChange = new EventEmitter();
  @Output() buttonSelected = new EventEmitter();

  @HostBinding("style.--titleColor") titleColor: string = 'black';
  @HostBinding("style.--backgroundColor") backgroundColor: string = 'white';

  constructor() { }

  ngOnInit(): void {
    if(this.display == true){
      setTimeout(()=>{                       
        this.display = false;
    }, 3000);
    }
  }
  onCancel() {
    this.display = false;
    this.displayChange.emit(this.display);
    this.buttonSelected.emit("cancel");
  }
  onConfirm() {
    this.display = false;
    this.displayChange.emit(this.display);
    this.buttonSelected.emit("confirm");
  }

}

