export const AppConfig = {
  DATE_FORMAT: 'dd/mm/yy',
  DATE_TIME_FORMAT: 'dd/mm/yy HH:mm',
  DATE_TIME_SEC_FORMAT: 'dd/mm/yy HH:mm:ss',
  NUMBER_REGEX: '^-?[0-9]{1,9}(?:\\.[0-9]{1,3})?$',
  NUMBER_FORMAT: '1.2-3',
  WEB_DATE_FORMAT: 'YYYY-MM-DD',
  WEB_DATE_TIME_SEC_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  PAGE_SIZE: 20,
  //FILE_UPLOAD_ACCEPT: '.pdf,.xlsx,.docx,.jpg,.png',
  FILE_UPLOAD_ACCEPT: 'application/pdf, .xlsx, .docx, image/*',
  FILE_UPLOAD_SIZE: 5000000,
  FILE_UPLOAD_LIMIT: null,
  LOADING: false,
  AUTH_API: [
    "/api/Account/",
  ]
}
