import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { LogService } from '../log/log.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  responseCache = new Map();
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const cache = this.responseCache.get(request.urlWithParams);

    if (!this.canCache(request)) return next.handle(request);

    if (cache) return of(cache);

    return next.handle(request).pipe(
      tap((response) => {
        this.responseCache.set(request.urlWithParams, response);
      })
    );
  }

  canCache(request: HttpRequest<unknown>): boolean {
    return request.urlWithParams.includes('');
  }
}

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private logService: LogService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        next: (response) => {
          const start = Date.now();
          if (response.type == 0) return;
          if (!environment.production) {
            if (response instanceof HttpResponse) {
              const elapsed = Date.now() - start;

              console.groupCollapsed(
                'Fetch:',
                request.method,
                request.url,
                `${elapsed}ms`,
                `- ${response.status}`
              );
              console.log('%cELAPSED\t\t:', 'color: #885544', `${elapsed}ms`);
              console.log('%cMETHOD\t\t:', 'color: #449900', request.method);
              console.log('URL\t\t\t:', request.urlWithParams);
              console.log(
                'HEADER\t\t>',
                '[ApiKey]\t\t\t:' + request.headers.get('ApiKey')
              );
              console.log(
                '\t\t\t>',
                '[Signature]\t\t:' + request.headers.get('Signature')
              );
              console.log(
                '\t\t\t>',
                '[Language]\t\t:' + request.headers.get('Language')
              );
              console.log(
                '\t\t\t>',
                '[Authorization]\t:' + request.headers.get('Authorization')
              );
              console.log('%cBODY\t\t:', 'color: #d2691e', request.body);
              console.log('%cRESPONSE\t:', 'color: #0099ff', response.body);
              console.groupEnd();
            }
          }
        },
        error: (error) => {
          this.logService.error(
            `${request.method} ${request.url}`,
            request,
            error
          );
        },
      })
    );
  }
}
