import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { SessionService } from '../core/state/session/session.service';
import { SessionQuery } from '../core/state/session/session.query';
import { HttpsService } from '../core/services/https/https.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiPath: string;
  isLogin = false;
  roleAs: any;

  constructor(
    private sessionService: SessionService,
    private sessionQuery: SessionQuery,
    private https: HttpsService,
    private router: Router
  ) {
    this.apiPath = environment.apiPath;
  }

  login(value: string) {
    this.isLogin = true;
    this.roleAs = value;
    this.sessionService.setSession({ STATE: true, ROLE: this.roleAs });
    return of({ success: this.isLogin, role: this.roleAs });
  }

  logout() {
    let accessToken;
    let refreshToken;
    this.sessionQuery.getAccessToken().subscribe((val) => (accessToken = val));
    this.sessionQuery
      .getRefreshToken()
      .subscribe((val) => (refreshToken = val));
    this.https
      .post<any>(`${this.apiPath}/api/bo-auth/v1/logout`, {
        accessToken,
        refreshToken,
      })
      .subscribe(() => {
        setTimeout(() => {
          this.sessionService.logout();
          this.router.navigate(['']).then(() => window.location.reload());
        }, 2000);
      });
  }

  isLoggedIn() {
    this.sessionQuery.getState().subscribe((loggedIn) => {
      if (loggedIn) this.isLogin = true;
      else this.isLogin = false;
    });
    return this.isLogin;
  }

  getRole() {
    this.sessionQuery.getRole().subscribe((val) => (this.roleAs = val));
    return this.roleAs;
  }

  verifyToken(accessToken: string) {
    return this.https.post<any>(`${this.apiPath}/api/bo-auth/v1/login`, {
      googleIdToken: accessToken,
    });
  }

  verifyNewToken(refreshToken: string) {
    return this.https.post<any>(`${this.apiPath}/api/bo-auth/v1/refresh`, {
      refreshToken: refreshToken,
    });
  }

  getUserProfile(accessToken: string) {
    return this.https.post<any>(
      `${this.apiPath}/api/bo-user-inquiry-processor/v1/details`,
      {
        accessToken,
      }
    );
  }

  getMenuList() {
    return this.https.get<any>(
      `${this.apiPath}/api/bo-user-inquiry-processor/v1/menus`
    );
  }
}
