<div class="row">
  <div class="col-6">
    <h4>{{ title }}</h4>
  </div>
  <div
    *ngIf="showNumberOfList && data.length > 0"
    class="col-6 d-flex justify-content-end align-items-center mb-2"
  >
    <span>
      <body class="dropdown-label mr-2">
        <span>Number of list</span>
      </body>
    </span>
    <div>
      <p-dropdown
        [options]="numberOptions"
        optionLabel="label"
        optionValue="value"
        [(ngModel)]="selectedNumberOption.value"
        styleClass="styledTable"
        (ngModelChange)="onChangeNumberOfList($event)"
      ></p-dropdown>
    </div>
  </div>
</div>

<div class="row" *ngIf="data.length > 0">
  <div class="col-6 d-flex align-self-center">
    <body *ngIf="showListOf && data" class="paginator-label">
      <span>
        {{
          numberWithCommas(
            (this.responsePageNumber - 1) * selectedNumberOption.value + 1
          )
        }}
        -
        {{
          (this.responsePageNumber - 1) * selectedNumberOption.value +
            selectedNumberOption.value >
          totalItems
            ? numberWithCommas(totalItems)
            : numberWithCommas(
                (this.responsePageNumber - 1) * selectedNumberOption.value +
                  selectedNumberOption.value
              )
        }}
        of {{ numberWithCommas(totalItems) }}
      </span>
    </body>
  </div>
  <div class="col-6 d-flex justify-content-end" *ngIf="showExportBtn">
    <p-button
      label="Import User"
      id="btnCreate"
      *ngIf="showImportUserBtn"
      styleClass="p-button-sm p-button-rounded p-button-import"
      class="file-upload-button mr-2"
      [style]="{ width: 'auto' }"
    >
      <img class="import-icon" src="../../../../assets/images/Import.png" />
      <input type="file" accept=".xlsx" (change)="onImportUser($event)" />
    </p-button>
    <app-button
      id="btnCreate"
      *ngIf="showAddUserBtn"
      label="Add User"
      iconName="pi-plus"
      class="mr-2"
      (click)="onAddUser()"
    ></app-button>
    <app-button
      id="btnCreate"
      *ngIf="showCrateBtn"
      label="create"
      iconName="pi-plus"
      class="mr-2"
      (click)="onCreate()"
    ></app-button>
    <app-button
      label="Download CSV"
      iconName="pi-download"
      (clickEvt)="onClickDownload()"
    ></app-button>
  </div>
</div>

<div class="mt-2" *ngIf="data.length > 0">
  <p-table
    #dt
    [columns]="columns"
    [value]="data"
    [scrollable]="scrollable"
    [totalRecords]="totalItems"
    [rows]="rows"
    (onPageChange)="onPageChange($event)"
    styleClass="{{
      lastColFreeze ? 'freezeLastCol  styledTable' : 'styledTable'
    }}"
    [(first)]="firstRow"
    sortField="columns.sort"
    sortMode="single"
    scrollHeight="800px"
  >
    <!-- [paginator]="showPaginator" -->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th
          *ngFor="let col of columns"
          [pSortableColumn]="col.sort"
          [pSortableColumnDisabled]="col.sort ? false : true"
        >
          <div>
            {{ col.header }}
            <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template
      pTemplate="body"
      let-list
      let-columns="columns"
      let-rowIndex="rowIndex"
    >
      <tr>
        <td
          *ngFor="let col of columns"
          [style.background-color]="rowIndex % 2 == 0 ? '#F4F9FE' : '#FFFFFF'"
          [style]="{ width: list[col.width] }"
        >
          <span [ngSwitch]="col.field">
            <!-- ########## customer-management ########## -->
            <span *ngSwitchCase="'customerPrivilegeStatus'">
              <span
                [ngClass]="{
                  'status-blacklist': list.customerPrivilegeStatus === 'B',
                  'status-ep': list.customerPrivilegeStatus === 'EP',
                  'status-inactive': list.customerPrivilegeStatus === 'I',
                  'status-active': list.customerPrivilegeStatus === 'A'
                }"
              >
                {{
                  list.customerPrivilegeStatus == "A"
                    ? "Active"
                    : list.customerPrivilegeStatus == "I"
                    ? "Inactive"
                    : list.customerPrivilegeStatus == "EP"
                    ? "End Privilege"
                    : list.customerPrivilegeStatus == "B"
                    ? "Blacklist"
                    : "-"
                }}
              </span>
            </span>

            <span *ngSwitchCase="'blacklistSourceDate'">
              <span>
                {{
                  list.blacklistSourceDate
                    ? (list.blacklistSourceDate | date : "dd MMM yyyy")
                    : "-"
                }}
              </span>
            </span>

            <span *ngSwitchCase="'blacklistEffectiveDateTime'">
              <span>
                {{
                  list.blacklistEffectiveDateTime
                    ? (list.blacklistEffectiveDateTime | stringToDate)
                    : "-"
                }}
              </span>
            </span>
            <span *ngSwitchCase="'updatedBy'">
              <span> {{ list.updatedBy ? list.updatedBy : "-" }} </span>
            </span>

            <span *ngSwitchCase="'totalAmount'">
              <span>
                {{ list.totalAmount || "0.00" | number : ".2" }}
              </span>
            </span>

            <span *ngSwitchCase="'spendingAmount'">
              <span>
                {{ list.spendingAmount || "0.00" | number : ".2" }}
              </span>
            </span>

            <span *ngSwitchCase="'availableAmount'">
              <span>
                {{ list.availableAmount || "0.00" | number : ".2" }}
              </span>
            </span>

            <span *ngSwitchCase="'redeemableCount'">
              <span *ngIf="list.redeemableCount === 0"> 0 </span>
              <span *ngIf="list.redeemableCount !== 0">
                {{ list.redeemableCount || "-" }}
              </span>
            </span>

            <span *ngSwitchCase="'totalTransaction'">
              <span>
                {{ list.totalTransaction || "0" }}
              </span>
            </span>

            <span *ngSwitchCase="'latestRedemptionDate'">
              <span>
                {{
                  list.latestRedemptionDate
                    ? (list.latestRedemptionDate | date : "dd MMM yyyy")
                    : "-"
                }}
              </span>
            </span>

            <span *ngSwitchCase="'endDate'">
              <span>
                {{ list.endDate ? (list.endDate | date : "dd MMM yyyy") : "-" }}
              </span>
            </span>

            <span *ngSwitchCase="'redeemableStartDate'">
              <span>
                {{
                  list.redeemableStartDate
                    ? (list.redeemableStartDate | date : "dd MMM yyyy")
                    : "-"
                }}
              </span>
            </span>
            <!-- ########## customer-management ########## -->

            <!-- ########## customer-transaction-management ########## -->
            <span *ngSwitchCase="'transactionStatus'">
              <span
                [ngClass]="{
                  'status-reversed': list.transactionStatus === 'Reversed',
                  'status-void': list.transactionStatus === 'Voided',
                  'status-active': list.transactionStatus === 'success',
                  'status-pending': list.transactionStatus === 'processing'
                }"
              >
                {{ capitalizeFirstLetter(list.transactionStatus) }}
              </span>
            </span>

            <span *ngSwitchCase="'transactionType'">
              <span>
                {{
                  list.transactionType === "R"
                    ? "Redeem"
                    : list.transactionType === "V"
                    ? "Void"
                    : list.transactionType === "RR"
                    ? "Reversed Redeem"
                    : list.transactionType === "RV"
                    ? "Reversed Void"
                    : ""
                }}
              </span>
            </span>
            <!-- ########## customer-transaction-management ########## -->

            <!-- ########## company-management ########## -->
            <span *ngSwitchCase="'status'">
              <span
                [ngClass]="{
                  'status-active':
                    list.status === 'A' || list.status === 'Completed',
                  'status-blacklist': list.status === 'B',
                  'status-cancelled': list.status === 'C',
                  'status-dead': list.status === 'D',
                  'status-inactive': list.status === 'I',
                  'status-pending': list.status
                    .toLowerCase()
                    .includes('pending'),
                  'status-reject': list.status === 'R'
                }"
              >
                {{
                  list.status == "A"
                    ? "Active"
                    : list.status == "B"
                    ? "Blacklist"
                    : list.status == "C"
                    ? "Cancelled"
                    : list.status == "D"
                    ? "Dead"
                    : list.status == "I"
                    ? "Inactive"
                    : list.status == "R"
                    ? "Rejected"
                    : "-"
                }}
                <br />
                <span style="font-size: 12px; color: #8a9fab">{{
                  list.submittedDate && list.submittedDate
                }}</span>
              </span>
            </span>

            <span *ngSwitchCase="'privilegeStatus'">
              <span
                [ngClass]="{
                  'status-active': list.privilegeStatus === 'A',
                  'status-blacklist': list.privilegeStatus === 'B',
                  'status-dead': list.privilegeStatus === 'D',
                  'status-inactive': list.privilegeStatus === 'I'
                }"
              >
                {{
                  list.privilegeStatus === "A"
                    ? "Active"
                    : list.privilegeStatus === "B"
                    ? "Blacklist"
                    : list.privilegeStatus === "D"
                    ? "Dead"
                    : list.privilegeStatus === "I"
                    ? "Inactive"
                    : "-"
                }}
              </span>
            </span>

            <span *ngSwitchCase="'privilegeCreatedBy'">
              <span>
                {{ list.privilegeCreatedBy ? list.privilegeCreatedBy : "-" }}
              </span>
            </span>

            <span *ngSwitchCase="'privilegeCreatedDate'">
              <span>
                {{
                  list.privilegeCreatedDate && list.privilegeCreatedTime
                    ? (list.privilegeCreatedDate + list.privilegeCreatedTime
                      | stringToDateCreatedDateTime)
                    : "-"
                }}
              </span>
            </span>

            <span *ngSwitchCase="'kcorpCompId'">
              <span>
                {{ list.kcorpCompId ? list.kcorpCompId : "-" }}
              </span>
            </span>

            <span *ngSwitchCase="'updatedDateTime'">
              <span>
                {{
                  list.updatedDateTime
                    ? (list.updatedDateTime | stringToDate)
                    : "-"
                }}
              </span>
            </span>

            <!-- ########## company-management ########## -->

            <span *ngSwitchCase="'customerStatus'">
              <span
                [ngClass]="{
                  'status-blacklist': list.customerStatus === 'Blacklist',
                  'status-dead': list.customerStatus === 'Dead',
                  'status-inactive': list.customerStatus === 'Inactive',
                  'status-active': list.customerStatus === 'Active'
                }"
              >
                {{ list.customerStatus }}
              </span>
            </span>

            <span *ngSwitchCase="'redeemableDate'">
              <span>
                {{
                  list.redeemableDate
                    ? (list.redeemableDate | date : "dd MMM yyyy")
                    : "-"
                }}
              </span>
            </span>

            <span *ngSwitchCase="'campaignStatus'">
              <span
                [ngClass]="{
                  'status-active': list.campaignStatus === 'Active',
                  'status-inactive': list.campaignStatus === 'Inactive'
                }"
              >
                {{ list.campaignStatus }}
              </span>
            </span>

            <span *ngSwitchCase="'companyStatus'">
              <span
                [ngClass]="{
                  'status-active': list.companyStatus === 'Active',
                  'status-inactive': list.companyStatus === 'Inactive'
                }"
              >
                {{ list.companyStatus }}
              </span>
            </span>

            <span *ngSwitchCase="'userStatus'">
              <span
                [ngClass]="{
                  'status-active': list.userStatus === 'Active',
                  'status-inactive': list.userStatus === 'Inactive'
                }"
              >
                {{ list.userStatus }}
              </span>
            </span>

            <span *ngSwitchCase="'companyPrivilegeCategoryStatus'">
              <span
                [ngClass]="{
                  'status-active':
                    list.companyPrivilegeCategoryStatus === 'Active',
                  'status-inactive':
                    list.companyPrivilegeCategoryStatus === 'Inactive'
                }"
              >
                {{ list.companyPrivilegeCategoryStatus }}
              </span>
            </span>

            <span *ngSwitchCase="'merchantId'" style="word-break: break-all">
              {{ list.merchantId }}
            </span>

            <span *ngSwitchCase="'button'">
              <p-button
                type="button"
                label="{{ col.btnConfig.label }}"
                icon="{{ col.btnConfig.icon || 'pi pi-align-justify' }}"
                styleClass="p-button-sm p-button-outlined p-button-plain"
                (click)="col.btnConfig.onClick(list)"
                class="styled-table-btn"
                [disabled]="col.btnConfig.disabled"
                [style.pointerEvents]="col.btnConfig.disabled ? 'none' : ''"
              ></p-button>
            </span>

            <span *ngSwitchDefault>
              <span *ngIf="col.field !== 'button' && !isArray(col.field)">
                {{ list[col.field] }}
              </span>
              <div *ngIf="isArray(col.field)">
                <ng-container
                  *ngIf="
                    col.header !== 'Campaign' &&
                    col.header !== 'Privilege Category' &&
                    col.header !== 'Privilege ID'
                  "
                >
                  <p *ngFor="let item of col.field">
                    {{ list[item] }}
                  </p>
                </ng-container>
                <ng-container
                  *ngIf="
                    col.header === 'Campaign' ||
                    col.header === 'Privilege Category' ||
                    col.header === 'Privilege ID'
                  "
                >
                  <span *ngFor="let item of col.field">
                    {{ list[item] ? list[item] : item }}
                  </span>
                </ng-container>
              </div>
            </span>
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    #pp
    *ngIf="showPaginator"
    [rows]="selectedNumberOption.value"
    [totalRecords]="totalItems"
    (onPageChange)="onPageChange($event)"
  ></p-paginator>
</div>

<div *ngIf="data.length === 0 && isShowNotFoundText" class="no-result">
  <body class="card-container">
    {{ notFoundText }}
  </body>
</div>
