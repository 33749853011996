<div class="styled-auto-complete-label">{{ label }}</div>
<div class="input-container">
  <input
    class="styled-input"
    type="text"
    pInputText
    [(ngModel)]="value"
    [attr.disabled]="disabled ? '' : null"
    [placeholder]="placeholder"
    [formControl]="emailForm"
    [style.borderColor]="'#3B82F6'"
    (ngModelChange)="this.warningText = ''"
  />
  <button
    pButton
    pRipple
    type="button"
    label="Add"
    icon="pi pi-plus"
    (click)="addItem()"
    [disabled]="false"
    class="p-button-rounded h-3rem align-self-end mx-2 px-4 py-2 add-btn"
  ></button>
</div>
<h5 *ngIf="warningText.length">
  {{ warningText }}
</h5>
<div class="list-container" *ngIf="lists && lists.length > 0">
  <div
    class="list"
    [class.disabled-list]="disabled"
    *ngFor="let item of lists; index as i"
  >
    <label class="label-text" [for]="i.toString()">{{ item }}</label>
    <i (click)="removeItem(i)" class="pi pi-times"></i>
  </div>
</div>
