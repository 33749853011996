import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PopupService } from 'src/app/core/services/popup.service';

@Component({
  selector: 'app-alert-pop-up',
  templateUrl: './alert-pop-up.component.html',
  styleUrls: ['./alert-pop-up.component.scss'],
})
export class AlertPopUpComponent implements OnInit {
  @Input() config: any = {};
  @Output() displayChange = new EventEmitter();
  @Output() buttonSelected = new EventEmitter();

  constructor(public router: Router, private popupService: PopupService) { }

  ngOnInit(): void {
    this.popupService.configObservable.subscribe((state) => {
      this.config = state;
    });
  }

  onCancel() {
    this.config.onCancel();
    this.popupService.hidePopup(this.config)
  }

  onConfirm() {
    this.config.onConfirm();
    this.popupService.hidePopup(this.config)
  }

  isHTML(str: string) {
    var a = document.createElement('div');
    a.innerHTML = str;

    for (var c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType == 1) return true;
    }

    return false;
  }
}
