import { Injectable } from '@angular/core';
import { SessionState, SessionStore } from './session.store';
import { BehaviorSubject, Observable, map, timer } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { SessionQuery } from './session.query';

@Injectable({ providedIn: 'root' })
export class SessionService {
  decodedToken: any;
  roleId$: BehaviorSubject<any | null>;
  roleId: Observable<any | null>;

  constructor(
    private authStore: SessionStore,
    private sessionQuery: SessionQuery
  ) {
    this.roleId$ = new BehaviorSubject(localStorage.getItem('roleId')!);
    this.roleId = this.roleId$.asObservable();
  }

  logout() {
    this.authStore.clearSession();
  }

  setSession(data: SessionState) {
    return timer(0)
      .pipe(map(() => data))
      .subscribe((session) => this.authStore.updateSession(session));
  }

  getRoleId() {
    const accessToken = this.sessionQuery.getAccessToken();
    accessToken.subscribe((token) => {
      this.decodedToken = jwt_decode(token);
      this.roleId$.next(token.sub);
    });
  }
}
