import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { SessionQuery } from 'src/app/core/state/session/session.query';
import { SpinnerService } from 'src/app/core/interceptors/spinner/spinner.service';
import { APP_NAME_EN, APP_NAME_TH } from '../../core/constants/app.constant';
import { LayoutService } from '../service/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  items!: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  app_name: {
    en: string;
    th: string;
  };
  userEmail: string = '';
  userImage: string = '';
  userRole: string = '';
  userProfile: any

  constructor(
    public layoutService: LayoutService,
    public spinnerService: SpinnerService,
    private sessionQuery: SessionQuery
  ) {
    this.app_name = {
      en: APP_NAME_EN,
      th: APP_NAME_TH,
    };

    this.sessionQuery.getUserProfile().subscribe((val) => {
      this.userProfile = val;
      this.userEmail  = this.userProfile?.userEmail;
      this.userImage = this.userProfile?.userImage;
      this.userRole = this.userProfile?.roleName;
    });
  }

  getRole() {
    this.sessionQuery.getRole().subscribe((value) => {
      switch (value) {
        case 'OPERATOR':
          this.userRole = 'Operator';
          break;
        case 'PROJECT_MEMBER':
          this.userRole = 'Project Member';
          break;
        case 'PROJECT_OWNER':
          this.userRole = 'Project Owner';
          break;
        case 'SUPER_USER':
          this.userRole = 'Super User';
          break;
      }
    });
  }
}
