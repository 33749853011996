import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

const SECONDS_TO_TIMEOUT = 15 * 60;

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService {
  val: any;
  private isTimeOut = new BehaviorSubject(false);
  public getLastAction() {
    return parseInt(localStorage.getItem('LAST_ACTION') || '');
  }
  public setLastAction(LAST_ACTION: number) {
    localStorage.setItem('LAST_ACTION', JSON.stringify(LAST_ACTION));
  }

  constructor(private router: Router) {
    this.check();
    this.initListener();
    this.initInterval();
    this.checkIsTimeOut();
  }

  initListener() {
    document.body.addEventListener('click', this.reset, false);
    document.body.addEventListener('mouseover', this.reset, false);
    document.body.addEventListener('mouseout', this.reset, false);
    document.body.addEventListener('keydown', this.reset, false);
    document.body.addEventListener('keyup', this.reset, false);
    document.body.addEventListener('keypress', this.reset, false);
    window.addEventListener('storage', this.storageEvt, false);
  }

  reset = () => {
    if (this.router.url !== '/') {
      this.setLastAction(Date.now());
    }
  };

  removeListener = () => {
    document.body.removeEventListener('click', this.reset, false);
    document.body.removeEventListener('mouseover', this.reset, false);
    document.body.removeEventListener('mouseout', this.reset, false);
    document.body.removeEventListener('keydown', this.reset, false);
    document.body.removeEventListener('keyup', this.reset, false);
    document.body.removeEventListener('keypress', this.reset, false);
    window.removeEventListener('storage', this.storageEvt, false);
  };

  initInterval() {
    setInterval(() => {
      if (this.router.url !== '/') {
        this.check();
      }
    }, 1000); //check interval
  }

  check() {
    if (!isNaN(this.getLastAction())) {
      const now = Date.now();
      const expireAt = this.getLastAction() + SECONDS_TO_TIMEOUT * 1000;
      const diff = expireAt - now;
      const isTimeout = diff < 0;
      this.isTimeOut.next(false);

      if (isTimeout) {
        this.isTimeOut.next(true);
      }
    }
  }

  checkIsTimeOut() {
    return this.isTimeOut.asObservable();
  }

  storageEvt() {
    this.val = localStorage.getItem('LAST_ACTION');
  }
}
