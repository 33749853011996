import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { LOADING } from "../../constants/app.constant";
import { AppConfig } from "../../configs/app.config";
import { HelperService } from "./../helper.service";

@Injectable({
  providedIn: 'root'
})
export class HttpsService {

  constructor(
    private http: HttpClient,
    private helper: HelperService,
    private router: Router
  ) { }


  get<T>(url: string, isLoading: boolean = AppConfig.LOADING) {
    let httpOptions = this.generateRequestOptions();
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    return this.http.get<T>(url, httpOptions).pipe(
      catchError((err) => {
        return this.handleError(err);
      }) // then handle the error
    );
  }

  getWithoutLoading<T>(url: string) {
    const httpOptions = this.generateRequestOptions({
      "x-NotShowLoading": "true",
    });
    return this.http.get<T>(url, httpOptions).pipe(
      catchError((err) => {
        return this.handleError(err);
      }) // then handle the error
    );
  }

  getWithQuery<T>(
    url: string,
    body: any,
    isLoading: boolean = AppConfig.LOADING
  ) {
    let normalizeObject: { [param: string]: string | string[] } = {};
    this.normalizeParamsObject(body, normalizeObject);
    const params = new HttpParams({ fromObject: normalizeObject });
    let httpOptions = this.generateRequestOptions(null, params);
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    return this.http.get<T>(url, httpOptions).pipe(
      catchError((err) => {
        return this.handleError(err);
      }) // then handle the error
    );
  }

  getWithQuery2<T>(
    url: string,
    body: any,
    isLoading: boolean = AppConfig.LOADING
  ) {
    const params = new HttpParams({ fromObject: body });
    let httpOptions = this.generateRequestOptions(null, params);
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    return this.http.get<T>(url, httpOptions).pipe(
      catchError((err) => {
        return this.handleError(err);
      }) // then handle the error
    );
  }

  post<T>(
    url: string,
    body: any,
    isLoading: boolean = AppConfig.LOADING
  ): Observable<T> {
    let httpOptions = this.generateRequestOptions();
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    return this.http
      .post<T>(url, body, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  postWithQuery<T>(
    url: string,
    body: any,
    getParams: any,
    isLoading: boolean = AppConfig.LOADING
  ): Observable<T> {
    let normalizeObject: { [param: string]: string | string[] } = {};
    this.normalizeParamsObject(getParams, normalizeObject);
    const params = new HttpParams({ fromObject: normalizeObject });
    let httpOptions = this.generateRequestOptions(null, params);
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    return this.http
      .post<T>(url, body, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  postWithForm<T>(
    url: string,
    body: any,
    isLoading: boolean = AppConfig.LOADING
  ): Observable<T> {
    let httpOptions = this.generateRequestOptions();
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    const formBody = this.objectToFormData(body, null, null);
    return this.http
      .post<T>(url, formBody, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  postWithQueryForm<T>(
    url: string,
    body: any,
    getParams: any,
    isLoading: boolean = AppConfig.LOADING
  ): Observable<T> {
    let normalizeObject: { [param: string]: string | string[] } = {};
    this.normalizeParamsObject(getParams, normalizeObject);
    const params = new HttpParams({ fromObject: normalizeObject });
    let httpOptions = this.generateRequestOptions(null, params);
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    const formBody = this.objectToFormData(body, null, null);
    return this.http
      .post<T>(url, formBody, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  postWithoutLoading<T>(
    url: string,
    body: any,
    isLoading: boolean = AppConfig.LOADING
  ): Observable<T> {
    let httpOptions = this.generateRequestOptions({
      "x-NotShowLoading": "true",
    });
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    return this.http
      .post<T>(url, body, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  delete<T>(
    url: string,
    isLoading: boolean = AppConfig.LOADING
  ): Observable<T> {
    let httpOptions = this.generateRequestOptions();
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    return this.http.delete<T>(url, httpOptions).pipe(
      catchError((err) => {
        return this.handleError(err);
      }) // then handle the error
    );
  }

  deletWithParams<T>(
    url: string,
    body: any,
    isLoading: boolean = AppConfig.LOADING
  ) {
    let normalizeObject: { [param: string]: string | string[] } = {};
    this.normalizeParamsObject(body, normalizeObject);
    const params = new HttpParams({ fromObject: normalizeObject });
    let httpOptions = this.generateRequestOptions(null, params);
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    return this.http.delete<T>(url, httpOptions).pipe(
      catchError((err) => {
        return this.handleError(err);
      }) // then handle the error
    );
  }

  deletWithBody<T>(
    url: string,
    body: any,
    isLoading: boolean = AppConfig.LOADING
  ) {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      body: body,
    };
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    return this.http.delete<T>(url, httpOptions).pipe(
      catchError((err) => {
        return this.handleError(err);
      }) // then handle the error
    );
  }

  put<T>(
    url: string,
    body: any,
    isLoading: boolean = AppConfig.LOADING
  ): Observable<T> {
    let httpOptions = this.generateRequestOptions();
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    return this.http.put<T>(url, body, httpOptions).pipe(
      catchError((err) => {
        return this.handleError(err);
      }) // then handle the error
    );
  }
  putForm<T>(
    url: string,
    body: any,
    isLoading: boolean = AppConfig.LOADING
  ): Observable<T> {
    let httpOptions = this.generateRequestOptions();
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    let formBody = new FormData();
    if (body.files) {
      if (body.files.length) {
        for (let i = 0; i < body.files.length; i++) {
          formBody.append("Files", body.files[i], body.files[i].name);
        }
      } else {
        formBody.append("Files", body.files, body.files.name);
      }
    }
    let objectKeys = Object.keys(body);
    objectKeys.forEach((key) => {
      if (Array.isArray(body[key])) {
        if (body[key].length === 0) {
        } else if (key.toLocaleLowerCase() !== "files") {
          body[key].forEach((item: any) => {
            formBody.append(key.charAt(0).toUpperCase() + key.slice(1), item);
          });
        }
      } else if (key.toLocaleLowerCase() !== "files" && body[key] !== null) {
        formBody.append(key.charAt(0).toUpperCase() + key.slice(1), body[key]);
      }
    });
    return this.http
      .put<T>(url, formBody, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  putWithForm<T>(
    url: string,
    body: any,
    isLoading: boolean = AppConfig.LOADING
  ): Observable<T> {
    let httpOptions = this.generateRequestOptions();
    httpOptions = this.generateRequestLoading(httpOptions, isLoading);
    const formBody = this.objectToFormData(body, null, null);
    return this.http
      .put<T>(url, formBody, httpOptions)
      .pipe(catchError((error) => this.handleError(error)));
  }

  private generateRequestOptions(
    headerObject: any = null,
    paramsObject: any = null
  ): {
    headers: HttpHeaders;
    params?: HttpParams;
    responseType?: any;
    observe?: any;
  } {
    // let token = '';
    let token = this.helper.getLocalStorage("");
    let headers = new HttpHeaders();

    if (token) {
      headers = headers.set("Authorization", `Bearer ${token}`);
    } else {
      // this.helper.logout();
    }
    if (headerObject !== null && headerObject !== undefined) {
      let keys = Object.keys(headerObject);
      keys.forEach(
        (key) => (headers = headers.set(key, headerObject[key]))
        // (key) => (headers = headers.append(key, headerObject[key]))
        // (key) => (headers[key] = headerObject[key])
      );
    }
    let httpOptions: any = { headers };

    if (paramsObject !== null && headerObject !== undefined) {
      httpOptions["params"] = paramsObject;
    }

    return httpOptions;
  }

  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error) {
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        console.error("An error occurred:", error.error);
      }
      if (error.status === 401) {
        console.log(error?.error);

        this.helper.logOut();
      }

      else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        console.error(
          `Backend returned code ${error.status}, ` + `body was: ${error.error}`
        );
      }
    } 

    // Return an observable with a user-facing error message.
    // return throwError('Something bad happened; please try again later.');
    return throwError(error) as Observable<any>;
  }

  private normalizeParamsObject(obj: any, newObj: any, parentKey: string = "") {
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null) {
        if (typeof obj[key] !== "object") {
          newObj[parentKey + key] = obj[key];
        } else {
          if (Array.isArray(obj[key])) {
            for (let index = 0; index < obj[key].length; index++) {
              const element = obj[key][index];
              if (element !== "object") {
                newObj[parentKey + key + `[${index}]`] = obj[key][index];
              } else
                this.normalizeParamsObject(
                  element,
                  newObj,
                  key + `[${index}].`
                );
            }
          } else {
            this.normalizeParamsObject(obj[key], newObj, key + ".");
          }
        }
      }
    });
  }

  private objectToFormData(obj: any, rootName: any, ignoreList: any) {
    var formData = new FormData();

    const appendFormData = (data: any, root: any) => {
      if (!ignore(root)) {
        root = root || "";
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          for (var i = 0; i < data.length; i++) {
            if (data[i] instanceof File) {
              formData.append(root, data[i]);
            } else {
              appendFormData(data[i], root + "[" + i + "]");
            }
          }
        } else if (typeof data === "object" && data) {
          for (var key in data) {
            if (data.hasOwnProperty(key)) {
              if (root === "") {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + "." + key);
              }
            }
          }
        } else {
          if (data !== null && typeof data !== "undefined" && data !== "") {
            formData.append(root, data);
          }
        }
      }
    };

    const ignore = (root: any) => {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function (x) {
          return x === root;
        })
      );
    };

    appendFormData(obj, rootName);

    return formData;
  }

  private generateRequestLoading(headerObject: any, isLoading: boolean) {
    if (isLoading) {
      if (headerObject.headers.has(LOADING)) {
        headerObject.headers = headerObject.headers.set(
          LOADING,
          String(isLoading)
        );
      } else {
        headerObject.headers = headerObject.headers.append(
          LOADING,
          String(isLoading)
        );
      }
    }
    return headerObject;
  }
}

