import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-form-datepicker',
  templateUrl: './form-datepicker.component.html',
  styleUrls: ['./form-datepicker.component.scss'],
})
export class FormDatepickerComponent implements OnInit {
  @Input() value: any = null;
  @Input() isRequired: boolean = false;
  @Input() isSelectDate: boolean = true;
  @Input() label: string = '';
  @Input() timePlaceholder: string = 'Select Time';
  @Input() datePlaceholder: string = 'Select Date';
  @Input() disabled: boolean = false;
  @Input() warningText: string = '';
  @Input() invalid: boolean = false;
  @Input() maxWidth: string = '1000px';
  @Input() selectionMode: string = 'single';
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() autoSelectNow: boolean = false;

  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() controlName: string = '';

  @Output() valueChange = new EventEmitter();
  @Output() panelChange = new EventEmitter();
  constructor() { }

  onShow() {
    if (!this.formGroup.controls[this.controlName].value) {
      this.formGroup.controls[this.controlName].setValue(moment().format('HH:mm'));
    }
  }

  checkTimePattern() {
    let newValue = this.formGroup.controls[this.controlName].value.split(":")
    this.formGroup.controls[this.controlName].value.split(":").map((item : string, index: number) => {
      if ((index === 0 && Number(item) > 23) || (index === 1 && Number(item) > 59)) {
        newValue[index] = "00"
      }
    })
    this.formGroup.controls[this.controlName].setValue(newValue.join(":"))
  }

  ngOnInit(): void {
    let control: FormControl = new FormControl('');
    this.formGroup.addControl(this.controlName, control);
  }
}
