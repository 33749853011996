import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit {
  @Input() value: string | null = null;
  @Input() isRequired: boolean = false;
  @Input() label: string = '';
  @Input() placeholder: string = 'Please enter a message';
  @Input() disabled: boolean = false;
  @Input() warningText: string | null | boolean = '';
  @Input() Invalid: boolean = false;

  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() controlName: string = '';

  @Input() maxLength?: string;
  @Input() width?: string;

  @Output() valueChange = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    let control: FormControl = new FormControl('');
    this.formGroup.addControl(this.controlName, control);
  }

  onChangeValue($event: any) {
    if ($event.target.value === '') {
      this.formGroup.get(this.controlName)?.setValue(null);
    }
  }
}
