<div class="d-flex justify-content-center align-items-center login-bg">
    <div class="login-card">
      <div class="p-4">
        <p style="text-align: center">
          {{ constants.mainMessage }}
        </p>
        <p class="m-0" style="text-align: center">
          <a href="transaction-history" style="text-align: center"
            >{{ constants.linkMessage }}</a
          >
        </p>
      </div>
    </div>
    <div class="layout-footer">
      <h6>
        {{ constants.footerMessage }}
      </h6>
    </div>
  </div>