import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LayoutComponent } from './layout/layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { MenuComponent } from './layout/menu/menu.component';
import { MenuItemComponent } from './layout/menu/menu-item.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { FooterComponent } from './layout/footer/footer.component';

import { ShareModule } from './share/share.module';
import { UiModule } from './share/components/ui/ui.module';
import { DecimalPipe } from '@angular/common';

import { MenubarModule } from 'primeng/menubar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorServiceInterceptor } from './core/interceptors/error-service.interceptor';
import { RequestServiceInterceptor } from './core/interceptors/request-service.interceptor';
import { LogPublishersService } from './share/log-publishers/log-publishers-service';
import { LogService } from './core/services/log/log.service';
import { LoggingInterceptor } from './core/services/interceptors/interceptor';
import { InterceptorService } from './core/interceptors/spinner/interceptor.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './core/services/custom-route-reuse-strategy';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HeaderComponent,
    MenuComponent,
    MenuItemComponent,
    SidebarComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MenubarModule,
    AppRoutingModule,
    UiModule,
    ShareModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule,
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorServiceInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestServiceInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
    DecimalPipe,
    LogService,
    LogPublishersService,
    MessageService,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
