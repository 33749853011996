import {
  Component,
  OnInit,
  Input,
  HostBinding,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-email-list-input',
  templateUrl: './email-list-input.component.html',
  styleUrls: ['./email-list-input.component.scss'],
})
export class EmailListInputComponent implements OnInit {
  value: string = '';
  emailForm = new FormControl(null, [Validators.email, Validators.required]);
  @Input() label: string = '';
  @Input() placeholder: string = 'Please input here';
  @Input() disabled: boolean = false;
  @Input() lists: any[] = [];

  @HostBinding('style.--width') width: string = '35vw';
  @HostBinding('style.--maxWidth') maxWidth: string = '1000px';

  @Output() listsChange = new EventEmitter<any>();

  warningText = '';
  selectedLists: any[] = [];
  constructor() {}

  ngOnInit(): void {}

  addItem() {
    this.setWarningText(this.value);
    if (
      this.value &&
      !this.lists.includes(this.value) &&
      !this.setWarningText(this.value)
    ) {
      this.lists = [...this.lists, this.value];
      this.value = '';
      this.emailForm.markAsPristine();
      this.emailForm.markAsUntouched();
      this.emailForm.updateValueAndValidity();
      this.listsChange.emit(this.lists);
    }
  }

  setWarningText(value: string) {
    if (this.emailForm.hasError('email')) {
      this.warningText = 'Invalid Email Format';
      return true;
    }
    if (this.lists.includes(value)) {
      this.warningText = 'Email already exists';
      return true;
    }
    if (this.emailForm.hasError('required') && this.emailForm.value === '') {
      this.warningText = 'Please input email';
      return true;
    }
    return false;
  }


  removeItem(index: number) {
    this.lists = this.lists.filter((item, i) => i !== index);
    this.listsChange.emit(this.lists);
  }
}
