import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

interface radioButton {
  label: string;
  onSelect: void;
  value: string;
}

@Component({
  selector: 'app-form-input-number',
  templateUrl: './form-input-number.component.html',
  styleUrls: ['./form-input-number.component.scss'],
})
export class FormInputNumberComponent implements OnInit {
  @Input() value: number = 0;
  @Input() isRequired: boolean = false;
  @Input() radioValue: string = '';
  @Input() label: string = '';
  @Input() placeholder: any = '';
  @Input() suffix: string = '';
  @Input() radioButtonList: radioButton[] = [];
  @Input() disabled: boolean = false;
  @Input() warningText: string = '';
  @Input() invalid: boolean = false;
  @Input() suggestionText: string = '';

  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() controlName: string = '';

  @Output() valueChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {
    let control: FormControl = new FormControl('');
    this.formGroup.addControl(this.controlName, control);
  }
}
