import { Injectable } from '@angular/core';
import { LogPublisher } from 'src/app/share/log-publishers/log-publishers';
import { LogPublishersService } from 'src/app/share/log-publishers/log-publishers-service';
import moment from 'moment';

export enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6,
}

@Injectable({
  providedIn: 'root',
})
export class LogService {
  level: LogLevel = LogLevel.All;
  logWithDate: boolean = true;
  publishers: LogPublisher[];

  constructor(private publishersService: LogPublishersService) {
    this.publishers = this.publishersService.publishers;
  }

  private shouldLog(level: LogLevel): boolean {
    let isShouldLog: boolean = false;

    if (
      (level >= this.level && level !== LogLevel.Off) ||
      this.level === LogLevel.All
    ) {
      isShouldLog = true;
    }

    return isShouldLog;
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    if (this.shouldLog(level)) {
      let entry: LogEntry = new LogEntry();
      entry.message = msg;
      entry.level = level;
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;
      for (let logger of this.publishers) {
        logger.log(entry).subscribe((response) => {});
      }
    }
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }
}

export class LogEntry {
  entryDate: Date = new Date();
  message: string = '';
  level: LogLevel = LogLevel.Debug;
  extraInfo: any[] = [];
  logWithDate: boolean = true;

  buildLogString(): string {
    const log: any = {};
    log.type = LogLevel[this.level]; 
    log.message = this.message;

    if (this.logWithDate) {
      log.date = moment(new Date()).format('DD MMMM YYYY, hh:mm:ss');
    }

    if (this.extraInfo.length) {
      log.extraInfo = this.extraInfo;
    }

    return log;
  }

  private formatParams(params: any[]): string {
    let stringParams: string = params.join(',');

    if (params.some((p) => typeof p == 'object')) {
      stringParams = '';
      for (let item of params) {
        stringParams += JSON.stringify(item) + ',';
      }
    }

    return stringParams;
  }
}
