import { Component, OnInit } from '@angular/core';
import { AppConstants } from 'src/app/core/constants/app.constant';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constants: {
    footerMessage: string;
  }
  constructor() {
    this.constants = {
      footerMessage: AppConstants.FOOTER_MESSAGE 
    }
  }

  ngOnInit(): void {
  }

}
