import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
  @Input() defaultLabel: string = 'defaultLabel';
  @Input() optionLabel: string = 'name';
  @Input() data: { name: string; code: string }[] = [];
  @Input() selectedData: any[] = [];
  @Input() disabled: boolean = false;
  @Output() selectedDataChange = new EventEmitter<any[]>();

  @HostBinding('style.--matrix') matrix: string = 'matrix(1, 0, 0, 1, 0, 0)';

  @Input() formGroup: FormGroup = new FormGroup({});
  @Input() controlName: string = '';

  constructor() {}

  ngOnInit(): void {
    let control: FormControl = new FormControl('');
    this.formGroup.addControl(this.controlName, control);
  }

  onPanelShow() {
    this.matrix = 'matrix(1, 0, 0, -1, 0, 0)';
  }

  onPanelHide() {
    this.matrix = 'matrix(1, 0, 0, 1, 0, 0)';
  }

  onChange() {
    this.selectedDataChange.emit(this.selectedData);
  }

  adjustHeight() {
    return this.formGroup.get(this.controlName)!.value
      ? this.formGroup.get(this.controlName)!.value.length
      : null;
  }
}
