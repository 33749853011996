<p-button
  [type]="type"
  styleClass="styledBtn p-button-rounded"
  [style]="{ width: width, height: height }"
  [label]="label ? label : ''"
  [disabled]="disabled"
  (onClick)="onClick()"
  [class.outlined-button]="classBtn === 'outlined'"
  [class.outlined-danger]="classBtn === 'outlined-danger'"
  icon="pi {{iconName}}"
></p-button>
