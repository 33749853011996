import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { PopupService } from 'src/app/core/services/popup.service';
import { BehaviorSubject } from 'rxjs';
import { Columns, numberOption } from 'src/app/core/interfaces/base.interface';
import { AppConstants } from 'src/app/core/constants/app.constant';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent implements OnInit {
  constants: {
    downloadCompleteMessage: string;
    unableLoadDateMessage: string;
    exeedsDownloadLimit: string;
  };
  private _data = new BehaviorSubject<any[]>([]);
  @Input() columns: Columns[] = [];
  // @Input() data: any[] = [];
  @Input()
  set data(value) {
    this._data.next(value);
  }
  get data() {
    this.paginator?.changePage(this.responsePageNumber - 1);
    return this._data.getValue();
  }
  @Input() numberOptions: numberOption[] = [];
  @Input() title: string = '';
  @Input() showExportBtn: boolean = true;
  @Input() showCrateBtn: boolean = false;
  @Input() showImportUserBtn: boolean = false;
  @Input() showAddUserBtn: boolean = false;
  @Input() showNumberOfList: boolean = true;
  @Input() showListLabel: boolean = true;
  @Input() showSelectRow: boolean = true;
  @Input() showListOf: boolean = true;
  @Input() showPaginator: boolean = true;
  @Input() scrollable: boolean = true;
  @Input() lastColFreeze: boolean = false;
  @Input() firstRowFreeze: boolean = false;
  @Input() totalItems: number = 0;
  @Input() totalPages: number = 0;
  @Input() rows: number = 0;
  @Input() responsePageNumber: number = 1;
  @Input() notFoundText: string = 'Record Not Found';
  @Input() isShowNotFoundText: boolean = true;

  @Output() changePage = new EventEmitter();
  @Output() changeNumberOfList = new EventEmitter();
  @Output() clickCreate = new EventEmitter();
  @Output() clickImportUser = new EventEmitter();
  @Output() clickAddUser = new EventEmitter();

  @ViewChild('pp') paginator?: Paginator;

  step: number = 1;

  firstRow: number = 0;
  @Input() selectedNumberOption: numberOption;

  constructor(private popupService: PopupService) {
    this.constants = {
      downloadCompleteMessage:
        AppConstants.MODAL_MESSAGE.DOWNLOAD_COMPLETE_MESSAGE,
      unableLoadDateMessage:
        AppConstants.MODAL_MESSAGE.UNABLE_LOAD_DATA_MESSAGE,
      exeedsDownloadLimit:
        AppConstants.MODAL_MESSAGE.EXEEDS_DOWNLOAD_LIMIT_MESSAGE,
    };
    this.numberOptions = [
      { label: '10 List', value: 10 },
      { label: '25 List', value: 25 },
      { label: '50 List', value: 50 },
      { label: '100 List', value: 100 },
    ];
    this.selectedNumberOption = this.numberOptions[0];
  }

  ngOnInit(): void {}

  isArray(val: any): boolean {
    return Array.isArray(val);
  }

  onPageChange($event: any) {
    const currentPage = this.paginator?.getPage();
    if (currentPage !== this.responsePageNumber - 1) {
      this.changePage.emit({ $event, currentPage: currentPage });
    }
  }

  onChangeNumberOfList($event: any) {
    this.paginator?.changePage(0);
    this.changeNumberOfList.emit({ $event });
  }

  onCreate() {
    this.clickCreate.emit();
  }
  onImportUser(ev: any) {
    this.clickImportUser.emit(ev);
  }
  onAddUser() {
    this.clickAddUser.emit();
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  onClickDownload() {
    this.popupService.showPopup({
      display: true,
      title: this.constants.downloadCompleteMessage,
      buttonWidth: '100%',
      showCancelButton: false,
      confirmButtonText: 'OK',
      icon: 'assets/icons/success-icon.svg',
      onConfirm: () => {
        this.popupService.showPopup({
          display: true,
          title: this.constants.unableLoadDateMessage,
          buttonWidth: '100%',
          showCancelButton: false,
          confirmButtonText: 'OK',
          icon: 'assets/icons/warning-icon.svg',
          onConfirm: () => {
            this.popupService.showPopup({
              display: true,
              title: "Can't download",
              description: this.constants.exeedsDownloadLimit,
              buttonWidth: '100%',
              showCancelButton: false,
              confirmButtonText: 'OK',
              icon: 'assets/icons/warning-icon.svg',
              onConfirm: () => {
                this.popupService.hidePopup();
              },
            });
          },
        });
      },
    });
  }

  // numberWithCommas(number: string | number) {
  //   return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // }
  numberWithCommas(number: string | number) {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }
}
