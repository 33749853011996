import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionQuery } from '../core/state/session/session.query';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  menuList: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private sessionQuery: SessionQuery
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = state.url;
    return this.checkUserLogin(next, url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  checkUserLogin(route: ActivatedRouteSnapshot, url: any): any {
    this.sessionQuery.getMenus().subscribe((val) => {
      this.menuList = val;
      if (this.authService.isLoggedIn()) {
        localStorage.setItem('LAST_ACTION', Date.now().toString());

        if (this.menuList == null) {
          return false;
        }
        if (
          this.menuList?.findIndex(
            (x: { menuName: string }) =>
              x.menuName === route.data?.['breadcrumb']
          ) === -1 &&
          !route.data?.['notfound']
        ) {
          // this.router.navigate(['transaction-history']);
          return false;
        }
        if (route.data?.['notfound']) {
          this.router.navigate(['notfound']);
          return false;
        }
        return true;
      }

      this.router.navigate(['']);
      return false;
    });
  }
}
