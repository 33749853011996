import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent {
  @Input() index: any;
  @Input() tabs: any;
  @Output() onChangeTab = new EventEmitter();

  constructor() {}

  handleChange(e: { index: any }) {
    this.onChangeTab.emit(e.index);
  }
}
