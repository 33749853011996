// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    version: require('../../package.json').version + '-uat',
    storage: {
      salt: 'TEST_SALT',
    },
    clientId:'752920900456-p5vdjelb0hi0om7t10skpc3qj4avmk8v.apps.googleusercontent.com',
    // clientId:'194011939632-a67m11m267dkine3d6cd7lc7i473b95q.apps.googleusercontent.com'
    apiPath: 'https://extgw-cmmn-adm-portal-uat.arisetech.dev'
  };
   
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  