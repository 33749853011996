import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PopupService } from '../services/popup.service';
import { AppConstants } from '../constants/app.constant';
import { Router } from '@angular/router';

@Injectable()
export class ErrorServiceInterceptor implements HttpInterceptor {
  constants: {
    unableLoadDataMessage: string;
    disablePopupUrl: string[];
  };
  constructor(private popupService: PopupService, private router: Router) {
    this.constants = {
      unableLoadDataMessage:
        AppConstants.MODAL_MESSAGE.UNABLE_LOAD_DATA_MESSAGE,
      disablePopupUrl: ['/', 'unauthorized', 'notfound'],
    };
  }

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const start = Date.now();
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(() => {
          if (!environment.production) {
            const elapsed = Date.now() - start;
            console.groupCollapsed(
              'Fetch:',
              req.method,
              req.url,
              `${elapsed}ms`,
              `- ${err.status}`
            );
            console.error('%cELAPSED\t\t:', 'color: #885544', `${elapsed}`);
            console.error('%cSTATUS\t\t:', 'color: #885544', `${err.status}`);
            console.error(
              '%cSTATUS TEXT\t\t:',
              'color: #885544',
              `${err.statusText}`
            );
            console.error('%cMETHOD\t\t:', 'color: #885544', req.method);
            console.error('URL\t\t\t:', req.urlWithParams);
            console.error(
              'HEADER\t\t>',
              '[ApiKey]\t\t\t:' + req.headers.get('ApiKey')
            );
            console.error(
              '\t\t\t>',
              '[Signature]\t\t:' + req.headers.get('Signature')
            );
            console.error(
              '\t\t\t>',
              '[Language]\t\t:' + req.headers.get('Language')
            );
            console.error(
              '\t\t\t>',
              '[Authorization]\t:' + req.headers.get('Authorization')
            );
            console.groupEnd();
          }
          if (err.status === 500) {
            this.popupService.showPopup({
              display: true,
              title: AppConstants.MODAL_MESSAGE.INTERNAL_SERVER_ERROR,
              buttonWidth: '100%',
              showCancelButton: false,
              confirmButtonText: 'OK',
              icon: 'assets/icons/warning-icon.svg',
              onConfirm: () => {
                this.popupService.hidePopup();
              },
            });
          } else if (
            err.status != 401 &&
            !this.constants.disablePopupUrl.includes(this.router.url) &&
            err.error.code !== 'WF22004' &&
            err.error.code !== 'WF22005'
          ) {
            this.popupService.showPopup({
              title: this.constants.unableLoadDataMessage,
              buttonWidth: '100%',
              showCancelButton: false,
              confirmButtonText: 'OK',
              icon: 'assets/icons/warning-icon.svg',
              onConfirm: () => {
                this.popupService.hidePopup();
              },
            });
          }
        });
      })
    );
  }
}
