import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { LayoutService } from '../service/layout.service';
import { AuthService } from '../../auth/auth.service';
import { PopupService } from 'src/app/core/services/popup.service';
import { SessionQuery } from 'src/app/core/state/session/session.query';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/core/constants/app.constant';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  constants: {
    logoutMessage: string;
    menuList: any[];
  };
  role: any = this.authService.roleAs;

  modelData: any[] = [];
  menu: any[] = [];
  activeMenuList: any;
  routerLinkList: any;
  logoutBtn: boolean = false;

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService,
    private sessionQuery: SessionQuery,
    public router: Router,
    private popupService: PopupService
  ) {
    this.constants = {
      logoutMessage: AppConstants.MODAL_MESSAGE.LOGOUT_MESSAGE,
      menuList: AppConstants.MENU_LIST,
    };
    this.routerLinkList = {
      'Request Summary': '/request-summary',
      'Sponsor Management': '/sponsor-management',
      'Campaign Management': '/campaign-management',
      'Customer Management': '/customer-management',
      'Company Management': '/company-management',
      'Email Management': '/email-management',
      'Transaction History': '/transaction-history',
      'User Management': '/user-management',
      'Import': '/import',
      'Audit Log': '/audit-log',
      'Log Out': '/',
    };

    this.sessionQuery.getMenus().subscribe((val) => {
      this.activeMenuList = val;
      if (this.activeMenuList && this.menu.length == 0) {     
        for (let i = 0; i < this.constants.menuList.length; i++) {
          if (this.constants.menuList[i].menuName != 'Log Out') {
            let list = {
              label: this.constants.menuList[i].menuName,
              routerLink:
                this.routerLinkList[this.constants.menuList[i].menuName],
              disabled: this.activeMenuList.filter((item : any) => item.menuId == this.constants.menuList[i].menuId).length === 0,
              routerLinkOptions: true
            };
            this.menu.push(list);
          } else if (this.constants.menuList[i].menuName == 'Log Out') {
            this.logoutBtn = true;
          }
        }
      }
    });
  }

  ngOnInit() {}

  logout() {
    this.popupService.showPopup({
      title: this.constants.logoutMessage,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Log Out',
      icon: 'assets/icons/logout-modal-icon.svg',
      onCancel: () => {},
      onConfirm: () => {
        this.authService.logout();
      },
    });
  }
}
