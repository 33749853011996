import { Injectable } from '@angular/core';
import { Store, StoreConfig, resetStores } from '@datorama/akita';
import { storage } from 'src/main';

export interface Menu {
  menuId: number;
  menuName: string;
  menuLevel: number;
  menuParentId: number;
  orderNo?: number;
  status?: number;
}

export interface userCampaign {
  campaignId: string;
}

export interface userProfile {
  userEmail: string;
  roleName: string;
  userImage: string;
  roleId: string;
}

export interface userCampaign {
  campaignId: string
}

export interface SessionState {
  accessToken?: string | null;
  refreshToken?: string | null;
  menuList?: Menu[] | null;
  userProfile?: userProfile | null;
  STATE?: boolean;
  ROLE?: number | null;
  userCampaign?: userCampaign[] | null 
}

export function createInitialState(): SessionState {
  return {
    accessToken: null,
    refreshToken: null,
    menuList: null,
    userProfile: null,
    STATE: false,
    ROLE: null,
    userCampaign: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState());
  }

  updateSession(session: SessionState) {
    this.update(session);
  }

  clearSession() {
    this.update(createInitialState());
    storage.destroy();
    sessionStorage.clear();
  }
}
