<ul class="layout-menu">
  <ng-container *ngFor="let item of menu; let i = index">
    <li
      app-menu-item
      *ngIf="!item.separator"
      [item]="item"
      [index]="i"
      [root]="true"
    ></li>
    <li *ngIf="item.separator" class="menu-separator"></li>
  </ng-container>
  <button *ngIf="true" class="logout" (click)="logout()"><i class="pi pi-fw ico"></i>Log Out</button>
</ul>
