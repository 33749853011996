import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { TagModule } from 'primeng/tag';
import { ChipModule } from 'primeng/chip';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PaginatorModule } from 'primeng/paginator';

import { FormInputComponent } from './components/form-input/form-input.component';
import { FormDropdownComponent } from './components/form-dropdown/form-dropdown.component';
import { FormDatepickerComponent } from './components/form-datepicker/form-datepicker.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import { UiModule } from './components/ui/ui.module';
import { ToprightPopupComponent } from './components/topright-popup/topright-popup.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';

import { HttpClientModule } from '@angular/common/http';
import { FormInputNumberComponent } from './components/form-input-number/form-input-number.component';
import { EmailListInputComponent } from './components/email-list-input/email-list-input.component';
import { AlertPopUpComponent } from './components/alert-pop-up/alert-pop-up.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { StringToDate } from './components/datatable/string-to-date.pipe';
import { StringToDateCreatedDateTime } from './components/datatable/string-to-date-createdDateTime.pipe';

@NgModule({
  declarations: [
    FormInputComponent,
    FormDropdownComponent,
    FormDatepickerComponent,
    DatatableComponent,
    ToprightPopupComponent,
    MultiSelectComponent,
    FormInputNumberComponent,
    PopUpComponent,
    EmailListInputComponent,
    AlertPopUpComponent,
    ProgressSpinnerComponent,
    StringToDate,
    StringToDateCreatedDateTime,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DropdownModule,
    TableModule,
    ButtonModule,
    TreeTableModule,
    ColorPickerModule,
    InputMaskModule,
    BadgeModule,
    OverlayPanelModule,
    MenuModule,
    TooltipModule,
    BreadcrumbModule,
    PanelMenuModule,
    CardModule,
    DividerModule,
    PanelModule,
    CalendarModule,
    AccordionModule,
    ToolbarModule,
    InputTextModule,
    ConfirmDialogModule,
    CheckboxModule,
    ToastModule,
    DialogModule,
    InputTextareaModule,
    ContextMenuModule,
    ChipsModule,
    RadioButtonModule,
    InputNumberModule,
    TabViewModule,
    AutoCompleteModule,
    RippleModule,
    SplitButtonModule,
    ToggleButtonModule,
    MenubarModule,
    MultiSelectModule,
    TagModule,
    UiModule,
    ChipModule,
    FileUploadModule,
    HttpClientModule,
    InputSwitchModule,
    ProgressSpinnerModule,
    PaginatorModule,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    DropdownModule,
    TableModule,
    ButtonModule,
    TreeTableModule,
    ColorPickerModule,
    InputMaskModule,
    BadgeModule,
    OverlayPanelModule,
    MenuModule,
    TooltipModule,
    BreadcrumbModule,
    PanelMenuModule,
    CardModule,
    DividerModule,
    PanelModule,
    CalendarModule,
    AccordionModule,
    ToolbarModule,
    InputTextModule,
    ConfirmDialogModule,
    CheckboxModule,
    ToastModule,
    DialogModule,
    InputTextareaModule,
    FileUploadModule,
    ContextMenuModule,
    ChipsModule,
    RadioButtonModule,
    InputNumberModule,
    TabViewModule,
    AutoCompleteModule,
    RippleModule,
    SplitButtonModule,
    ToggleButtonModule,
    SplitButtonModule,
    MenubarModule,
    FormInputComponent,
    FormDropdownComponent,
    FormDatepickerComponent,
    DatatableComponent,
    MultiSelectModule,
    TagModule,
    UiModule,
    ToprightPopupComponent,
    MultiSelectComponent,
    PopUpComponent,
    ChipModule,
    InputSwitchModule,
    FormInputNumberComponent,
    EmailListInputComponent,
    AlertPopUpComponent,
    ProgressSpinnerModule,
    ProgressSpinnerComponent,
  ],
})
export class ShareModule {}
