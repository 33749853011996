<div
  class="datepicker-container"
  [ngSwitch]="isSelectDate"
  [style.maxWidth]="maxWidth"
>
  <div class="datepicker-label">
    {{ label }}<span *ngIf="isRequired">*</span>
  </div>
  <p-calendar
    appendTo="@body"
    *ngSwitchCase="true"
    styleClass="styled-datepicker"
    [(ngModel)]="value"
    (ngModelChange)="valueChange.emit(value)"
    [showIcon]="true"
    [placeholder]="datePlaceholder"
    [disabled]="disabled"
    [selectionMode]="selectionMode"
    [formControl]="$any(formGroup.controls[controlName])"
    [maxDate]="maxDate || null"
    [minDate]="minDate || null"
    dateFormat="dd M yy"
    [ngClass]="invalid && warningText ? 'error' : ''"
    dataType="string"
    [readonlyInput]="true"
  ></p-calendar>
  <!-- <p-calendar
    appendTo="@body"
    *ngSwitchCase="false"
    styleClass="styled-datepicker"
    [(ngModel)]="value"
    [showIcon]="true"
    [placeholder]="timePlaceholder"
    icon="pi pi-clock"
    [timeOnly]="true"
    dataType="string"
    [hourFormat]="'24'"
    [disabled]="disabled"
    [formControl]="$any(formGroup.controls[controlName])"
    [ngClass]="invalid ? 'error' : ''"
    (onBlur)="(onBlur)"
    [readonlyInput]="true"
    (onFocus)="autoSelectNow && onShow()"
  ></p-calendar> -->
  <p-inputMask
    *ngSwitchCase="false"
    mask="99:99"
    [(ngModel)]="value"
    placeholder="HH:mm"
    [disabled]="disabled"
    [formControl]="$any(formGroup.controls[controlName])"
    [ngClass]="invalid ? 'error' : ''"
    (onFocus)="autoSelectNow && onShow()"
    (onBlur)="checkTimePattern()"
  ></p-inputMask>
  <h5
    [ngClass]="warningText.length > 30 ? 'reduce-text-size' : null"
    *ngIf="invalid && warningText"
  >
    {{ warningText }}
  </h5>
</div>
